import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { langProps } from "../../components/types"
import Layout from "../../components/Layout"

export default (props: langProps) => {
    return (
        <Layout {...props} lang="en" >
            <div className="banner blue" style={{minHeight:'70vh'}}>
                <h1 style={{marginTop: 60}}>Thank you for your message</h1>
                <p>Your message has been successfully sent and we will get back to you as soon as possible.</p>
            </div>
        </Layout>
    )
}


// add headers
import Headers from '../../components/Headers'
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) =>
    <Headers
        title="Message sent"
        noindex
        location={location}
    />

